import React, { useState } from 'react';
import { Input, Button, Dropdown, Menu, Checkbox } from 'antd';
import "./CustomTextArea.css";
import { SendOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function CustomTextArea({
  imageCount,
  resolution,
  customWidth,
  customHeight,
  hdOption,
  setHdOption,
  handleResolutionChange,
  handleCustomResolutionInput,
  inputValue,
  onInputChange,
  handleImageCountChange,
  onGenerate
}) {
  const [isResolutionDropdownVisible, setIsResolutionDropdownVisible] = useState(false);
  const [isImageCountDropdownVisible, setIsImageCountDropdownVisible] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState("分辨率");
  const [selectedImageCount, setSelectedImageCount] = useState("图片数");

  const maxChars = 100; // 字数限制

  const handleResolutionMenuClick = (e) => {
    const newResolution = e.key;
    handleResolutionChange(newResolution);
    setSelectedResolution(newResolution);
    setIsResolutionDropdownVisible(false);
  };

  const handleImageCountMenuClick = (e) => {
    const newCount = e.key;
    handleImageCountChange(newCount);
    setSelectedImageCount(newCount);
    setIsImageCountDropdownVisible(false);
  };

  const handleTextInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxChars) {
      onInputChange(e); // 仅当字符数在限制内时更新输入
    }
  };

  const resolutionMenu = (
    <Menu onClick={handleResolutionMenuClick}>
      <Menu.Item key="1280x720">1280x720</Menu.Item>
      <Menu.Item key="1920x1080">1920x1080</Menu.Item>
    </Menu>
  );

  const imageCountMenu = (
    <Menu onClick={handleImageCountMenuClick}>
      <Menu.Item key="1">1</Menu.Item>
      <Menu.Item key="2">2</Menu.Item>
      <Menu.Item key="4">4</Menu.Item>
      <Menu.Item key="8">8</Menu.Item>
    </Menu>
  );

  return (
    <div className="custom-textarea">
      <TextArea
        placeholder="请输入提示词，描述想要生成的画面说明。"
        value={inputValue}
        onChange={handleTextInputChange}
        autoSize={{ minRows: 6, maxRows: 8 }}
        className="custom-textarea__input"
      />
      <div className="custom-textarea__char-count">
        {inputValue.length}/{maxChars}
      </div>
      <div className="custom-textarea__options">
        {/* <Dropdown
          overlay={resolutionMenu}
          trigger={['click']}
          visible={isResolutionDropdownVisible}
          onVisibleChange={(visible) => setIsResolutionDropdownVisible(visible)}
        >
          <Button className="custom-textarea__dropdown-button" onClick={() => setIsResolutionDropdownVisible(!isResolutionDropdownVisible)}>
            {selectedResolution}
          </Button>
        </Dropdown>
        
        <Dropdown
          overlay={imageCountMenu}
          trigger={['click']}
          visible={isImageCountDropdownVisible}
          onVisibleChange={(visible) => setIsImageCountDropdownVisible(visible)}
        >
          <Button className="custom-textarea__dropdown-button" onClick={() => setIsImageCountDropdownVisible(!isImageCountDropdownVisible)}>
            {selectedImageCount}
          </Button>
        </Dropdown>

        <Checkbox
          checked={hdOption}
          onChange={(e) => setHdOption(e.target.checked)}
          className="custom-textarea__hd-checkbox"
        >
          <span>高清</span>
        </Checkbox> */}
      </div>
      <Button
        className="custom-textarea__generate-button"
        onClick={onGenerate}
        icon={<SendOutlined />}
      >
        生成
      </Button>
    </div>
  );
}

export default CustomTextArea;
