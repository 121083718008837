// src/hooks/useImageGallery.js

import { useState, useEffect, useContext } from 'react';
import { message } from 'antd';
import { fetchHistory } from '@services/apiService';
import { AuthContext } from '@contexts/AuthContext';
import eventEmitter from '@utils/eventEmitter';

const useImageGallery = () => {
    const { token, isAuthenticated, currentFunc } = useContext(AuthContext);
  
    const [imagesByBatch, setImagesByBatch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [prefix, setPrefix] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
  
    const constructImageUrl = (path, prefix) => `${prefix}${path}`;
  
    const processImages = (batch, prefix) => {
      try {
        const decompressedResult = JSON.parse(batch.result);
        return decompressedResult.map((item) => ({
          thumbUrl: constructImageUrl(item.thumb_path, prefix),
          uuid: item.uuid,
          seed: item.seed,
          fit: item.fit,
          feedback: item.feedback,
          lora: item.lora,
          prompt: batch.prompt,
          fullUrl: constructImageUrl(item.path, prefix),
          raw: batch.init_images ? constructImageUrl(batch.init_images, prefix) : null,
        }));
      } catch {
        message.error('解析结果失败');
        return [];
      }
    };
  
    const processBatch = async (batch, prefix) => {
      let images = [];
  
      if (batch.status === 'unprocess' || batch.status === 'processing') {
        return {
          date: batch.created_at,
          images,
          request_type: batch.request_type,
          status: batch.status === 'unprocess' ? 'queueing' : 'processing',
          request_id: batch.request_id,
          favorite: batch.favorite,
          task_id: batch.task_id,
        };
      }
  
      if (batch.init_images) {
        images.push({
          thumbUrl: constructImageUrl(batch.init_images, prefix),
          fullUrl: constructImageUrl(batch.init_images, prefix),
          seed: -1,
          prompt: '原始图像',
        });
      }
  
      const generatedImages =
        batch.result && batch.result !== 'Generating'
          ? processImages(batch, prefix)
          : [
              {
                fullUrl: `${prefix}/assert/default.jpeg`,
                thumbUrl: `${prefix}/assert/default.jpeg`,
                seed: null,
                prompt: 'Loading...',
              },
            ];
  
      images = [...images, ...generatedImages];
  
      return {
        date: batch.created_at,
        images,
        request_type: batch.request_type,
        status: 'processed',
        request_id: batch.request_id,
        favorite: batch.favorite,
        task_id: batch.task_id,
      };
    };
  
    const loadHistory = async (page = 1, size = pageSize, func = currentFunc) => {
      if (!token) return;

      setLoading(true);
      try {
        const response = await fetchHistory(page, size, func);
        const { data: historyData, total: totalItems, prefix: newPrefix } = response.data;
        setPrefix(newPrefix);
        setTotalItems(totalItems);
        setCurrentPage(page);
  
        const imagesByBatch = await Promise.all(
          historyData.map((batch) => processBatch(batch, newPrefix))
        );
        setImagesByBatch(imagesByBatch);
      } catch (error) {
        message.error('获取历史数据失败');
      } finally {
        setLoading(false);
      }
    };
  
    const handleWebSocketMessage = (messageData) => {
      if (messageData.type === '1001') {
        loadHistory(currentPage, pageSize, currentFunc);
      }
      if (messageData.type === '1000') {
        const { request_id } = messageData;
        setImagesByBatch((prevImagesByBatch) =>
          prevImagesByBatch.map((batch) =>
            batch.request_id === request_id
              ? { ...batch, status: 'processing' }
              : batch
          )
        );
      }
    };

    useEffect(() => {
      if (token) {
        eventEmitter.on('websocketMessage', handleWebSocketMessage);
        return () => {
          eventEmitter.off('websocketMessage', handleWebSocketMessage);
        };
      }
    }, [token, currentFunc]);
  
    return {
      imagesByBatch,
      loading,
      currentPage,
      totalItems,
      pageSize,
      currentFunc,
      loadHistory,
      setPageSize,
      setCurrentPage,
      constructImageUrl,
      setImagesByBatch,
    };
  };
  

export default useImageGallery;
