// src/components/ImageGallery.js

import React, { useImperativeHandle, forwardRef, useEffect, useContext } from 'react';
import '@styles/ImageGallery.css';
import { Pagination, message } from 'antd';
import { debounce } from 'lodash';
import useImageGallery from '@hooks/useImageGallery';
import { AuthContext } from '@contexts/AuthContext';

const ImageGallery = forwardRef((props, ref) => {
  const { token, isAuthenticated, currentFunc } = useContext(AuthContext);

  const {
    imagesByBatch,
    currentPage,
    totalItems,
    pageSize,
    loadHistory,
  } = useImageGallery();

  const handlePageChangeDebounced = debounce((page, pageSize) => {
    loadHistory(page, pageSize, currentFunc);
  }, 300);

  useImperativeHandle(ref, () => ({
    loadHistory,
  }));
  
  const handleImageSelect = (img, imageBatch) => {
    const imageDetails = {
      fullUrl: img.fullUrl,
      request_id: img.request_id,
      seed: img.seed,
      prompt: img.prompt,
      fit: img.fit,
      feedback: img.feedback,
      raw: img.raw,
      uuid: img.uuid,
    };
    props.onSelectImage(imageDetails, imageBatch);

    if (img.raw) {
      props.setRawImage?.(img.raw);
      props.setCurrentType?.('g');
    } else {
      props.setCurrentType?.('i');
      props.setShowComparison?.(false);
    }
  };

  useEffect(() => {
    // 当 currentFunc 变化时，重置为第一页并加载历史记录
    loadHistory(1, pageSize, currentFunc);
  }, [currentFunc]);

  const { selectedImage } = props;

  return (
    <div className="image-gallery-container">
      {imagesByBatch.map((batch, batchIndex) => (
        <div
          key={batchIndex}
          className={`batch-container ${
            batch.status === 'queueing' || batch.status === 'processing'
              ? 'blurred'
              : ''
          }`}
        >
          <h3 className="batch-date">
            {new Date(batch.date).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })
              .replace(/\//g, '-')
              .replace(/(\d{2}-\d{2})-(\d{4})/, '$1, $2')}
          </h3>
          {batch.status === 'queueing' || batch.status === 'processing' ? (
            <div className="processing-overlay">
              <span>
                {batch.status === 'queueing'
                  ? '排队等待中......'
                  : 'AI正在加紧绘制！预计需要10s！'}
              </span>
            </div>
          ) : (
            <div className="image-gallery">
              {[...Array(8)].map((_, index) => {
                const img = batch.images[index];
                return img ? (
                  <div
                    key={index}
                    className={`image-container ${
                      img.fullUrl === selectedImage ? 'selected' : ''
                    }`}
                    onClick={() => handleImageSelect(img, batch)}
                  >
                    <img src={img.thumbUrl} alt={`图片-${index}`} loading="lazy" />
                  </div>
                ) : (
                  <div key={index} className="image-container placeholder">
                    City AI
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}
      <div className="pagination-container">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={handlePageChangeDebounced}
        />
      </div>
    </div>
  );
});

export default ImageGallery;
