import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Layout, Input, Select, Slider, InputNumber } from 'antd';
import ImageGallery from '@components/ImageGallery';
import CategorySelector from '@components/bridge/CategorySelector';
import BridgeSelector from '@components/bridge/BridgeSelector';
import Login from '@components/login/Login';
import '@styles/QuickPaint.css';
import { loras, bridgeLoras } from '@configs/loras';
import categories from '@configs/categories';
import { sendGenerationRequest } from '@services/apiService';
import { withRouter } from '@utils/withRouter';
import { API_ENDPOINTS } from '@configs/api';
import { AssistantContext } from '@contexts/AssistantContext';
import { handleError } from '@utils/apiUtils';
import ImageDisplay from '@components/ImageDisplay';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@contexts/AuthContext';
import { Checkbox } from 'antd';
import CustomTextArea from '@components/input/CustomTextArea';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';
import AdvancedSettings from '@components/input/AdvancedSettings';
import Parameters from '@components/bridge/Parameters';
import { SendOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { TextArea } = Input;

function BridgeQuickPaint(props) {
  const [inputOne, setInputOne] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDetails, setImageDetails] = useState(null);
  const [toggles, setToggles] = useState(loras.map(() => false));
  const [modelVersion, setModelVersion] = useState(bridgeLoras[0].key);
  const [loraInput, setLoraInput] = useState(bridgeLoras[0].value);
  const [selectedBridgeType, setSelectedBridgeType] = useState(Object.keys(categories.specific)[0]);
  const [resolution, setResolution] = useState('1280x720');
  const [customWidth, setCustomWidth] = useState(1280);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageCount, setImageCount] = useState(1);
  const [hdOption, setHdOption] = useState(false);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [buttonPrompt, setButtonPrompt] = useState('');
  const imageGalleryRef = useRef(null);
  const categorySelectorRef = useRef(null);
  const [imageBatch, setImageBatch] = useState(null);


  const [paramsVisible, setParamsVisible] = useState(false);
  const [controlnetConditioningScale, setControlnetConditioningScale] = useState(0.3);
  const [strength, setStrength] = useState(0.7);
  const [guidanceScale, setGuidanceScale] = useState(7.5);


  const { showBubble, showVideoBubble } = useContext(AssistantContext);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const { breadcrumbPath } = useBreadcrumb();

  const navigate = useNavigate();

  const togglesInfo = useRef(
    loras.reduce((acc, lora, index) => {
      acc[index] = lora.value;
      return acc;
    }, {})
  ).current;

  const models = useRef(
    bridgeLoras.reduce((acc, lora) => {
      acc[lora.key] = lora.value;
      return acc;
    }, {})
  ).current;

  useEffect(() => {
    const { pathname } = props.router.location;
    sessionStorage.setItem('currentRoute', pathname);
    setCurrentFunc('bridge-q');
    updateLoraInput();
  }, [props.router.location]);

  const toggleParamsVisibility = () => {
    setParamsVisible(!paramsVisible); // 切换状态
  };

  const handleInputOneChange = (e) => {
    setInputOne(e.target.value);
  };

  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };


  const handleSelectionChange = (selectedText) => {
    setButtonPrompt(selectedText);
  };

  const handleToggle = (index) => {
    const newToggles = [...toggles];
    newToggles[index] = !newToggles[index];
    setToggles(newToggles);
    updateLoraInput({ toggles: newToggles });
  };

  const handleBridgeTypeChange = (value) => {
    const selectedModel = bridgeLoras.find((lora) => lora.name === value);
    if (selectedModel) {
      setSelectedBridgeType(value);
      setModelVersion(selectedModel.key);
      setLoraInput(selectedModel.value);
      updateLoraInput({ selectedBridgeType: value, modelVersion: selectedModel.key });
      categorySelectorRef.current?.clearSelections();
    }
  };

  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else {
      const [width, height] = value.split('x').map(Number);
      setCustomWidth(width);
      setCustomHeight(height);
      setResolution(value);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') {
      setCustomWidth(filteredValue);
    } else if (key === 'customHeight') {
      setCustomHeight(filteredValue);
    }
  };

  const handleImageCountChange = (count) => {
    setImageCount(count);
  };

  const updateLoraInput = (updatedState = {}) => {
    const updatedModelVersion = updatedState.modelVersion !== undefined ? updatedState.modelVersion : modelVersion;
    const updatedToggles = updatedState.toggles !== undefined ? updatedState.toggles : toggles;

    const modelLoraInput = models[updatedModelVersion];
    const togglesLoraInput = updatedToggles.reduce(
      (input, active, index) => (active ? input + togglesInfo[index] : input),
      ''
    );
    const newLoraInput = modelLoraInput + togglesLoraInput;
    setLoraInput(newLoraInput);
  };

  const selectImage = (imageDetails, imageBatch) => {
    setSelectedImage(imageDetails.fullUrl);
    setImageDetails(imageDetails);
    setImageBatch(imageBatch);
  };
  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true); 
      return;
    }

    const { width, height } = resolution === 'custom' ? { width: customWidth, height: customHeight } : parseResolution(resolution);

    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      prompt: inputOne,
      currentFunc,
      lora: loraInput,
      n_iter: imageCount,
      hdOption: hdOption,
      seed,
      requestClass: 'c1',
      cfg_scale: 7,
      width,
      height,
      force_task_id: 'x-' + Math.random().toString(36).slice(-8),
    });

    try {
      const response = await sendGenerationRequest(API_ENDPOINTS.txt2img, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };

  const onClickEnhance = async (params) => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }
  
    const { width, height } = resolution === 'custom' ? { width: customWidth, height: customHeight } : parseResolution(resolution);
  
    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      prompt: inputOne,
      currentFunc,
      request_id: imageDetails.request_id,
      n_iter: imageCount,
      width,
      height,
      force_task_id: 'f-' + Math.random().toString(36).slice(-8),
      controlnet_conditioning_scale: params.controlnet_conditioning_scale,
      strength: params.strength,
      guidance_scale: params.guidance_scale,
      control_image: imageDetails
    });
  
    try {
      const response = await sendGenerationRequest(API_ENDPOINTS.fluxt2i, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };
  
  
  const parseResolution = (resolution) => {
    const [width, height] = resolution.split('x').map(Number);
    return { width, height };
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      showBubble('提交任务成功！正在生成，请耐心等待哦!');
    }
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalVisible(false);
  };

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
    setIsLoginModalVisible(false);
    navigate(0);
  };

  return (
      <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: '#101214' }}>

        <div className="flex-container">
          <Content className="l1-left-container">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <span className="label-style" style={{ marginRight: '8px' }}>桥型</span>
            <BridgeSelector selectedBridgeType={selectedBridgeType} handleBridgeTypeChange={handleBridgeTypeChange} />
          </div>
          {toggles.map((active, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <span className="label-style" style={{ flex: 1, marginRight: '8px' }}>{loras[index].name}</span>
              <Checkbox 
                checked={active}
                onChange={() => handleToggle(index)}
                style={{ marginLeft: 'auto' }}
              />
            </div>
          ))}
             <Parameters 
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                hdOption={hdOption}
                setHdOption={handleHdOptionChange}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
              />
              <div className="down-section">
              <CategorySelector
                ref={categorySelectorRef}
                selectedBridgeType={selectedBridgeType}
                onSelectionChange={handleSelectionChange}
              />
            </div>
          </Content>

          <Content className="l1-middle-container">
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
            <ImageDisplay imageDetails={imageDetails} imageBatch={imageBatch}/>
            <CustomTextArea
              imageCount={imageCount}
              resolution={resolution}
              customWidth={customWidth}
              customHeight={customHeight}
              hdOption={hdOption}
              setHdOption={handleHdOptionChange}
              handleResolutionChange={handleResolutionChange}
              handleCustomResolutionInput={handleCustomResolutionInput}
              handleImageCountChange={handleImageCountChange}
              inputValue={inputOne}
              onInputChange={handleInputOneChange}
              onGenerate={onClickSend}
            />
          </Content>

          <Content className="l1-right-container">
          <AdvancedSettings
              paramsVisible={paramsVisible}
              toggleParamsVisibility={toggleParamsVisibility}
              controlnetConditioningScale={controlnetConditioningScale}
              setControlnetConditioningScale={setControlnetConditioningScale}
              strength={strength}
              setStrength={setStrength}
              guidanceScale={guidanceScale}
              setGuidanceScale={setGuidanceScale}
            />  
           <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button 
              type="primary" 
              onClick={onClickEnhance} 
              icon={<SendOutlined />}
              style={{ width: "100%", margin: '20px', fontSize: "16px", backgroundColor: "#3a3b3c" }}
            >
              图片增强
            </Button>
          </div>
            <div className="breadcrumb__down__section">
              <div>
              </div>
              <span className="breadcrumb__path">历史记录</span>
              <ImageGallery
                ref={imageGalleryRef}
                size={10}
                page={1}
                onSelectImage={selectImage}
                selectedImage={selectedImage}
              />
            </div>
        </Content>

        </div>

      {isLoginModalVisible && (
        <Login
          onClose={handleLoginModalClose}
          onLogin={handleLoginSuccess}
        />
      )}
      </Layout>

  );
}

export default withRouter(BridgeQuickPaint);
