// @components/home/ImageUnit.js
import React, { useState } from 'react';
import './ImageUnit.css';

const ImageUnit = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="image-unit"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={data.url}
        alt={data.username}
        className="image-unit__image"
      />
      <div className="image-unit__overlay">
        {isHovered && (
          <div className="image-unit__prompt">
            <span className="image-unit__prompt-seed">Seed: {data.seed}</span>
          </div>
        )}
        <div className="image-unit__info">
          <div className="image-unit__user-container">
            <img 
              src={data.avatar_url} 
              alt="avatar" 
              className="image-unit__avatar"
            />
            <span className="image-unit__username">{data.username}</span>
          </div>
          <span className="image-unit__favorites">❤️ {data.favorites_counts}</span>
        </div>
      </div>
    </div>
  );
};

export default ImageUnit;