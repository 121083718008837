// src/pages/inpaint.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Layout, Checkbox } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Split from 'react-split';
import ImageGallery from '@components/ImageGallery';
import BridgeSelector from '@components/bridge/BridgeSelector';
import CustomInput from '@components/Inpaint/CustomInput';
import { DoodleSelector } from '@components/Inpaint/DoodleSelector';
import Login from '@components/login/Login';
import { MaskEditor, toMask } from '@components/mask-editor/MaskEditor';
import { loras, bridgeLoras } from '@configs/loras';
import categories from '@configs/categories';
import { AssistantContext } from '@contexts/AssistantContext';
import { sendGenerationRequest } from '@services/apiService';
import { API_ENDPOINTS } from '@configs/api';
import { handleError } from '@utils/apiUtils';
import '@styles/bridge/Inpaint.css';
import '@styles/SplitPane.css';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@contexts/AuthContext';
import ImageUploader from '@components/image/ImageUploader';
import Parameters from '@components/bridge/Parameters';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';

const { Content } = Layout;

const Inpaint = () => {
  const { showBubble, showVideoBubble } = useContext(AssistantContext);
  const [inputOne, setInputOne] = useState('');
  const [buttonPrompt, setButtonPrompt] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const canvasRef = useRef(null);
  const [resolution, setResolution] = useState('1280x720');
  const [randomTaskId, setRandomTaskId] = useState('');
  const [toggles, setToggles] = useState(loras.map(() => false));
  const [modelVersion, setModelVersion] = useState(bridgeLoras[0].key);
  const [loraInput, setLoraInput] = useState(bridgeLoras[0].value);
  const [selectedBridgeType, setSelectedBridgeType] = useState(Object.keys(categories.specific)[0]);
  const [rawImage, setRawImage] = useState(null);
  const [currentType, setCurrentType] = useState("g");
  const [showComparison, setShowComparison] = useState(false);
  const [customWidth, setCustomWidth] = useState(1280);
  const [hdOption, setHdOption] = useState(false);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageCount, setImageCount] = useState(1);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isDrawingAllowed, setIsDrawingAllowed] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [isSessionStorageSet, setIsSessionStorageSet] = useState(false);
  const [segResults, setSegResults] = useState(null);
  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const { breadcrumbPath } = useBreadcrumb();

  const categorySelectorRef = useRef(null);
  const imageGalleryRef = useRef(null);

  const navigate = useNavigate();
  const models = useRef(
    bridgeLoras.reduce((acc, lora) => {
      acc[lora.key] = lora.value;
      return acc;
    }, {})
  ).current;

  const togglesInfo = useRef(
    loras.reduce((acc, lora, index) => {
      acc[index] = lora.value;
      return acc;
    }, {})
  ).current;

  useEffect(() => {
    showVideoBubble();
    setCurrentFunc('bridge-i');
    const initialInput = buildLoraInput(modelVersion, toggles);
    setLoraInput(initialInput);
    setIsSessionStorageSet(true);
  }, [modelVersion, toggles]);

  const buildLoraInput = (modelVersion, toggles) => {
    let loraInput = models[modelVersion];
    toggles.forEach((active, index) => {
      if (active) loraInput += togglesInfo[index];
    });
    return loraInput;
  };

  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };

  const handleToggle = (index) => {
    const updatedToggles = toggles.map((item, idx) => (idx === index ? !item : item));
    const updatedInput = buildLoraInput(modelVersion, updatedToggles);
    setToggles(updatedToggles);
    setLoraInput(updatedInput);
  };

  const handleDoodleModeChange = (mode) => {
    setIsDrawingAllowed(mode === 'manual');
  };

  const handleBridgeTypeChange = (value) => {
    const selectedModel = bridgeLoras.find((lora) => lora.name === value);
    if (!selectedModel) {
      console.error(`No model found for bridge type: ${value}`);
      return;
    }

    const updatedInput = buildLoraInput(selectedModel.key, toggles);
    setSelectedBridgeType(value);
    setModelVersion(selectedModel.key);
    setLoraInput(updatedInput);

    if (categorySelectorRef.current) {
      categorySelectorRef.current.clearSelections();
    }
  };

  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }

    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      lora: loraInput,
      prompt: inputOne,
      n_iter: imageCount,
      seed,
      cfg_scale: 7,
      currentFunc,
      requestClass: 'c3',
      width: customWidth,
      height: customHeight,
      force_task_id: "x-" + Math.random().toString(36).slice(-8),
      init_images: [selectedImage],
      mask: toMask(canvasRef.current),
      controlnet: [],
    });

    setRandomTaskId(randomTaskId);
    try {
      const response = await sendGenerationRequest(API_ENDPOINTS.img2img, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };

  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else {
      const [width, height] = value.split('x').map(Number);
      setCustomWidth(width);
      setCustomHeight(height);
      setResolution(value);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') {
      setCustomWidth(filteredValue);
    } else if (key === 'customHeight') {
      setCustomHeight(filteredValue);
    }
  };

  const handleImageCountChange = (count) => {
    setImageCount(count);
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      showBubble('任务提交成功');
    }
    imageGalleryRef.current?.loadHistory(page, size, currentFunc);
  };

  const handleLoginModalClose = () => setIsLoginModalVisible(false);

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
    setIsLoginModalVisible(false);
    navigate(0);
  };

  const selectImage = (imageDetails) => {
    setSelectedImage(imageDetails.fullUrl);
  };

  return (
    <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: '#101214' }}>
      <div className="flex-container">
        <Content className="l1-left-container">
          <div className="breadcrumb__down__section">
          <span className="breadcrumb__path">历史记录</span>
              {isSessionStorageSet && (
                <ImageGallery
                  ref={imageGalleryRef}
                  size={size}
                  page={page}
                  onSelectImage={selectImage}
                  setRawImage={setRawImage}
                  setShowComparison={setShowComparison}
                  setCurrentType={setCurrentType}
                />
              )}
              </div>
          </Content>
          <Content className="l1-middle-container">
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
            {selectedImage ? (
              <div className="canvas-container">
                {selectedImage && currentType === 'g' && (
                  <Button
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      zIndex: 10,
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      color: 'black',
                    }}
                    onClick={() => setShowComparison(!showComparison)}
                  >
                    {showComparison ? '关闭对比' : '对比'}
                  </Button>
                )}

                {showComparison && rawImage ? (
                  <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={selectedImage} alt="Selected Image" />}
                    itemTwo={<ReactCompareSliderImage src={rawImage} alt="Raw Image" />}
                  />
                ) : (
                  <MaskEditor
                    src={selectedImage}
                    canvasRef={canvasRef}
                    isDrawingAllowed={isDrawingAllowed}
                    segResults={segResults}
                  />
                )}
                <CloseCircleOutlined
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    fontSize: '24px',
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedImage(null)}
                />
              </div>
            ) : (
              <ImageUploader onImageSelected={setSelectedImage}/>
            )}
          </Content>
          <Content className="l1-right-container">
            <DoodleSelector
              onModeChange={handleDoodleModeChange}
              src={selectedImage}
              setSegResult={setSegResults}
              showBubble={showBubble}
            />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <span className="label-style" style={{ marginRight: '8px' }}>桥型</span>
                  <BridgeSelector selectedBridgeType={selectedBridgeType} handleBridgeTypeChange={handleBridgeTypeChange} />
                </div>
                {toggles.map((active, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <span className="label-style" style={{ flex: 1, marginRight: '8px' }}>{loras[index].name}</span>
                    <Checkbox 
                      checked={active}
                      onChange={() => handleToggle(index)}
                      style={{ marginLeft: 'auto' }}
                    />
                  </div>
                ))}
             <Parameters 
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                hdOption={hdOption}
                setHdOption={handleHdOptionChange}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
              />
            <CustomInput placeholder="把两个桥塔都改成A型塔" value={inputOne} onChange={setInputOne} className="custom-textarea__input" />

            <div className="control-buttons">
              <Button type="primary" onClick={onClickSend} style={{ width: "100%" }}>
                生成
              </Button>
            </div>
          </Content>
      {isLoginModalVisible && (
        <Login onClose={handleLoginModalClose} onLogin={handleLoginSuccess} />
      )}
    </div>
      </Layout>
  );
};

export default Inpaint;
