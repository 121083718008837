import React, { useState } from 'react';
import { searchUser, inviteMember } from '@services/apiOrganizationService';
import '@styles/self/inviteMemberModal.css';

const InviteMemberModal = ({ isOpen, onClose, organizationUid }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleSearchChange = async (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value.length > 2) {
            setIsLoading(true);
            try {
                const response = await searchUser(e.target.value);
                setSearchResults(response.users);
            } catch (error) {
                console.error("Failed to search users", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    const handleInvite = async () => {
        if (selectedUser) {
            try {
                const inviteData = {
                    organization_uid: organizationUid,
                    inviter_uuid: localStorage.getItem("uuid"),
                    invitee_email: selectedUser.email,
                };
                await inviteMember(inviteData);
                alert("邀请发送成功！");
                onClose();
            } catch (error) {
                console.error("Failed to invite member", error);
                alert("邀请失败，请重试！");
            }
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="invite-member-modal">
            <div className="modal-content">
                <h2>邀请成员加入组织</h2>
                <input
                    type="text"
                    placeholder="搜索用户名或邮箱"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                {isLoading && <p>搜索中...</p>}
                {searchResults.length > 0 && (
                    <ul className="search-results">
                        {searchResults.map((user) => (
                            <li
                                key={user.uuid}
                                onClick={() => handleUserSelect(user)}
                                className={`result-item ${selectedUser?.uuid === user.uuid ? 'selected' : ''}`}
                            >
                                {user.username} ({user.email})
                            </li>
                        ))}
                    </ul>
                )}
                <div className="modal-buttons">
                    <button className="cancel-button" onClick={onClose}>
                        取消
                    </button>
                    <button className="invite-button" onClick={handleInvite} disabled={!selectedUser}>
                        确认邀请
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InviteMemberModal;
