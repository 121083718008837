// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Entrance from '@pages/Entrance';
import Main from '@pages/Main';
import Self from '@pages/self/Self';
import ProtectedRoute from '@components/others/ProtectedRoute';
import { AuthProvider } from '@contexts/AuthContext';
import { BreadcrumbProvider } from '@contexts/BreadcrumbContext';

const App = () => {
    return (
        <Router>
            <AuthProvider>
            <BreadcrumbProvider>
                <Routes>
                    <Route path="/entrance" element={<Entrance />} />
                    <Route path="/main/*" element={<Main />} />
                    <Route path="/self/*" element={<Self />} />
                    <Route path="/" element={<Entrance />} />
                </Routes>
                </BreadcrumbProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
