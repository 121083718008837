import React, { useState, useEffect, useContext } from 'react';
import '@styles/Login.css';
import { message } from 'antd';
import Register from '@components/login/Register';
import { getIpAddress, login, getLoginImage } from '@services/apiService';
import { AuthContext } from '@contexts/AuthContext';

const Login = ({ onClose, onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showRegister, setShowRegister] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { handleLogin } = useContext(AuthContext);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const images = await getLoginImage();
                setImageList(images);
            } catch (error) {
                console.error('获取登录图片失败：', error);
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        if (imageList.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
            }, 3000);
            return () => clearInterval(intervalId);
        }
    }, [imageList]);

    const handleLoginClick = async () => {
        if (!username || !password) {
            message.error('用户名或密码不能为空', 1.5);
            return;
        }

        try {
            const ip = await getIpAddress();
            const data = await login(username, password, ip);
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('uuid', data.user_info.uuid);
            onLogin(data.access_token);
            handleLogin(data.access_token);
            message.success('登录成功', 1.5);
        } catch (error) {
            console.log(error)
            message.error('登录失败，请检查用户名或密码', 1.5);
        }
    };

    const handleShowRegister = () => {
        setShowRegister(true);
    };

    const handleCloseRegister = () => {
        setShowRegister(false);
    };

    const handleRegisterSuccess = () => {
        setShowRegister(false);
        message.success('注册成功，请登录', 1.5);
    };

    return (
        <div className="login-modal">
            <div className="login-container">
                <button className="close-btn" onClick={onClose}>X</button>
                <div className="login-image">
                    {imageList.length > 0 && (
                        <img
                            src={imageList[currentImageIndex]}
                            alt="Login"
                            className="fade-in"
                        />
                    )}
                </div>
                <div className="login-content">
                    <h2>登录 Login</h2>
                    <input
                        type="text"
                        placeholder="用户名/邮箱"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="密码"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="button-group">
                        <button 
                            className="login-btn" 
                            onClick={handleLoginClick}
                            disabled={!username || !password}
                        >
                            登录
                        </button>
                        <button className="login-register-btn" onClick={handleShowRegister}>注册</button>
                    </div>
                </div>
                {showRegister && (
                    <Register
                        onClose={handleCloseRegister}
                        onRegister={handleRegisterSuccess}
                    />
                )}
            </div>
        </div>
    );
};

export default Login;
