// @configs/loras.js
export const loras = [
    {
      key: 'lora_1',
      name: '效果图',
      value: '<lora:Jiangda_xiaoguotu_0.2:0.3>',
    },
    {
      key: 'lora_2',
      name: '实景',
      value: '<lora:ZSofficeV1.0-000047:0.3>',
    }
  ];
  
  export const bridgeLoras = [
    {
      key: 'arch-bridge',
      name: '拱桥v1',
      value: '<lora:arch-bridge:0.7>',
    },
    {
      key: 'cable-stayed-bridge',
      name: '斜拉桥',
      value: 'cable-stayed bridge <lora:869p-256dim-50e043008:0.7>',
    },
    {
      key: 'suspension-bridge',
      name: '悬索桥',
      value: 'suspension bridge <lora:bridge-suspension-sdxl-000044:0.7>',
    },
    {
      key: 'arch-bridge2410',
      name: '拱桥v2',
      value: 'arch bridge <lora:bridge-arch-sdxl:0.7>',
    },
    {
      key: 'beam-bridge',
      name: '梁桥',
      value: 'beam bridge <lora:bridge-beam-sdxl:0.7>',
    },
    {
      key: 'truss-bridge',
      name: '桁架桥',
      value: 'truss bridge <lora:bridge-truss-sdxl:0.7>',
    },
    {
      key: 'pedestrian-overpass-bridge',
      name: '人行天桥',
      value: 'pedestrian overpass bridge <lora:bridge-pedestrian-overpass-sdxl-000076:0.7>',
    },
    {
      key: 'pedestrian-bridge',
      name: '人行桥',
      value: 'pedestrian bridge <lora:bridge-pedestrian-sdxl-000190:0.7>',
    },
    {
      key: 'sdxl-bridge-000084',
      name: '混合桥型',
      value: '<lora:bridge-nlp-sdxl:0.7>',
    }
  ];
  