import React, { useState, useEffect, useRef } from 'react';
import ImageUnit from '@components/home/ImageUnit';
import { getGroundImageData } from '@services/apiService';
import Masonry from 'react-masonry-css';
import './WorksSquare.css';

const WorksSquare = () => {
  const [works, setWorks] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(30);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef();
  const [columns, setColumns] = useState(1); // 初始列数设置为1

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      const minColumnWidth = 350; // 最小列宽，可以根据需要调整
      const maxColumns = 6; // 最大列数，可以根据需要调整
      const calculatedColumns = Math.floor(width / minColumnWidth);
      setColumns(Math.min(calculatedColumns, maxColumns));
    };

    updateColumns();
    window.addEventListener('resize', updateColumns);
    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  useEffect(() => {
    const loadWorks = async () => {
      setLoading(true);
      const response = await getGroundImageData(page, pageSize);
      const { data, total: newTotal } = response;

      setTotal(newTotal);
      setWorks((prevWorks) => (page === 1 ? data : [...prevWorks, ...data]));

      if (works.length + data.length >= newTotal) {
        setHasMore(false);
      }

      setLoading(false);
    };

    if (hasMore) {
      loadWorks();
    }
  }, [page, hasMore]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [loading, hasMore]);

  return (
    <div className="works-square">
      <Masonry
        breakpointCols={columns}
        className="masonry-grid"
        columnClassName="masonry-grid-column"
      >
        {works.map((work, index) => (
          <ImageUnit key={index} data={work} />
        ))}
      </Masonry>
      {loading && <div className="loading-text">Loading more works...</div>}
      {!loading && works.length === 0 && <div className="no-works-text">No works available.</div>}
      {/* {!hasMore && <div className="all-loaded-text">All works loaded.</div>} */}
      <div ref={observerRef} className="bottom-sensor"></div>
    </div>
  );
};

export default WorksSquare;
