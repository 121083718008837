import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select'; // 引入 react-select
import { getOrganization, getMembers, deleteMember } from '@services/apiOrganizationService';
import { AuthContext } from '@contexts/AuthContext';
import CreateOrganizationModal from '@components/self/CreateOrganizationModal';
import InviteMemberModal from '@components/self/InviteMemberModal';
import '@styles/self/organization.css';

const MyOrganization = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [members, setMembers] = useState([]);
    const { token, isAuthenticated } = useContext(AuthContext);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

    useEffect(() => {
        const fetchOrganizations = async () => {
            if (isAuthenticated && token) {
                try {
                    const data = await getOrganization();
                    setUserInfo(data.organizations);

                    // 默认选择第一个组织
                    if (data.organizations.length > 0) {
                        setSelectedOrganization(data.organizations[0]);
                    }
                } catch (error) {
                    console.error("Failed to load organizations", error);
                }
            }
        };
        fetchOrganizations();
    }, [isAuthenticated, token]);

    useEffect(() => {
        if (selectedOrganization) {
            fetchMembers(selectedOrganization.organization_uid);
        }
    }, [selectedOrganization]);

    const fetchMembers = async (organizationUid) => {
        try {
            const membersData = await getMembers(organizationUid);
            setMembers(membersData.members);
        } catch (error) {
            console.error("Failed to load members", error);
        }
    };

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleOrganizationCreated = (newOrganization) => {
        setUserInfo((prevUserInfo) => [...prevUserInfo, newOrganization]);
        setSelectedOrganization(newOrganization);
        setIsModalOpen(false);
        fetchMembers(newOrganization.organization_uid); 
    };

    const handleDeleteMember = async (memberUid) => {
        const isConfirmed = window.confirm("确定要删除该成员吗？");
        if (!isConfirmed) return;
    
        try {
            await deleteMember(selectedOrganization.organization_uid, memberUid);
            setMembers((prevMembers) => prevMembers.filter((member) => member.uuid !== memberUid));
        } catch (error) {
            console.error("Failed to delete member", error);
        }
    };

    const handleOrganizationSelect = (selectedOption) => {
        setSelectedOrganization(selectedOption.value);
    };

    const handleInviteMember = () => setIsInviteModalOpen(true);

    if (!userInfo) return <div className="info-loading">加载中...</div>;

    const organizationOptions = userInfo.map((org) => ({
        value: org,
        label: org.name,
    }));

    return (
        <div className="organization-container">
            {userInfo.length === 0 ? (
                <div className="no-organization">
                    <p>您还没有组织</p>
                    <button className="create-organization-button" onClick={handleOpenModal}>
                        创建组织
                    </button>
                </div>
            ) : (
                <>
                    <div className="organization-header">
                        <div className="organization-actions">
                            <Select
                                options={organizationOptions}
                                onChange={handleOrganizationSelect}
                                value={organizationOptions.find((option) => option.value === selectedOrganization)}
                                placeholder="选择组织"
                                className="custom-select"
                            />
                            <button className="invite-member-button" onClick={handleInviteMember}>
                                邀请成员
                            </button>

                            <button className="create-organization-button" onClick={handleOpenModal}>
                                创建组织
                            </button>
                        </div>
                        <InviteMemberModal
                            isOpen={isInviteModalOpen}
                            onClose={() => setIsInviteModalOpen(false)}
                            organizationUid={selectedOrganization?.organization_uid}
                        />
                    </div>

                    <div className="member-list">
                        <h2>{selectedOrganization?.name} 的成员</h2>
                        <ul>
                            {members.map((member) => (
                                <li key={member.uuid} className="member-item">
                                    <span>名称: {member.name}</span>
                                    <span>邮箱: {member.email}</span>
                                    <span>加入时间: {new Date(member.joined_at).toLocaleDateString()}</span>
                                    <span>{member.is_admin ? '管理员' : '成员'}</span>
                                    {selectedOrganization.is_admin && 
                                        !(member.is_admin && member.uuid === localStorage.getItem("uuid")) ? (
                                        <button
                                            className="delete-member-button"
                                            onClick={() => handleDeleteMember(member.uuid)}
                                        >
                                            删除
                                        </button>
                                    ) : (
                                        <button
                                            className="delete-member-button"
                                            disabled
                                            title="您无法删除管理员"
                                        >
                                            禁止
                                        </button>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
            <CreateOrganizationModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onOrganizationCreated={handleOrganizationCreated}
            />
        </div>
    );
};

export default MyOrganization;
