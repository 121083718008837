import React, { useState, useEffect } from 'react';
import '@styles/Login.css';
import { API_ENDPOINTS, API_HEADERS } from '@configs/api';
import { message } from 'antd';
import { getLoginImage } from '@services/apiService';

const Register = ({ onClose, onRegister }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState('');
    const [imageList, setImageList] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [countdown, setCountdown] = useState(0); // 倒计时状态
    const [isSending, setIsSending] = useState(false); // 发送状态

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const images = await getLoginImage();
                setImageList(images);
            } catch (error) {
                console.error('获取注册页面图片失败：', error);
            }
        };
        fetchImages();
    }, []);

    useEffect(() => {
        if (imageList.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
            }, 3000);
            return () => clearInterval(intervalId);
        }
    }, [imageList]);

    const handleSendCode = async () => {
        if (!email) {
            message.error('请输入有效的邮箱地址');
            return;
        }
        setIsSending(true); // 设置为发送中
        try {
            const response = await fetch(`${API_ENDPOINTS.sendCode}?email=${email}`, {
                method: 'POST',
                headers: API_HEADERS
            });
            if (response.ok) {
                message.success('验证码已发送，请检查您的邮箱');
                setCountdown(60); // 开始60秒倒计时
            } else {
                message.error('发送验证码失败，请稍后重试');
            }
        } catch (error) {
            message.error('请求失败，请稍后再试');
        }
        setIsSending(false); // 发送完成
    };

    // 倒计时处理
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            message.error('密码不一致，请重新输入', 1.5);
            return;
        }


        if (username.length < 3) {
            message.error('用户名长度必须至少3位', 1.5);
            return;
        }

        if (password.length < 8) {
            message.error('密码长度必须至少8位', 1.5);
            return;
        }

        const requestBody = {
            username,
            email,
            password,
            code
        };

        try {
            const response = await fetch(API_ENDPOINTS.register, {
                method: 'POST',
                headers: {
                    ...API_HEADERS,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                onRegister();
            } else {
                if (response.status === 421) {
                    message.error('暂不支持注册！', 1.5);
                } else {
                    const errorData = await response.json();
                    const errorMessage = errorData.detail.message || '注册失败';
                    message.error(errorMessage, 1.5);
                }
            }
        } catch (error) {
            message.error('请求失败，请稍后再试', 1.5);
        }
    };

    return (
        <div className="register-modal">
            <div className="register-container">
                <div className="register-image">
                    {imageList.length > 0 && (
                        <img
                            src={imageList[currentImageIndex]}
                            alt="Register"
                            className="fade-in"
                        />
                    )}
                </div>
                <div className="login-content">
                    <h2>注册 Register</h2>
                    <input
                        type="text"
                        placeholder="用户名，至少3位数字或字母"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="code-group">
                        <input
                            type="email"
                            placeholder="邮箱"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            onClick={handleSendCode}
                            disabled={countdown > 0 || isSending} // 倒计时或发送中禁用按钮
                        >
                            {countdown > 0 ? `${countdown}秒后重试` : '发送验证码'}
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="验证码"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="密码，至少8位数字或字母"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="确认密码"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div className="button-group">
                        <button onClick={handleRegister}>注册</button>
                        <button onClick={onClose}>取消</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
