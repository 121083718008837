import React, { useState, useEffect, useContext } from 'react';
import { getCurrentUser, uploadAvatar } from '@services/apiService';
import { AuthContext } from '@contexts/AuthContext';
import './Info.css';

const Info = () => {
    const [userInfo, setUserInfo] = useState(null);
    const { isAuthenticated } = useContext(AuthContext);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            getCurrentUser(token)
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => console.error('Failed to fetch user info:', error));
        }
    }, [isAuthenticated]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const response = await uploadAvatar(file);
                setUserInfo(prev => ({ ...prev, avatar_url: response.avatar_url }));
            } catch (error) {
                console.error('Failed to upload avatar:', error);
            }
        }
    };

    if (!userInfo) {
        return <div className="info-loading">加载中...</div>;
    }

    return (
        <div className="info-container">
            <div
                className="avatar-section"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <img src={userInfo.avatar_url} alt="Avatar" className="avatar-image" />
                {isHovering && (
                    <div className="camera-overlay">
                        <label htmlFor="avatar-upload" className="camera-icon">
                            <input
                                type="file"
                                id="avatar-upload"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            📷
                        </label>
                    </div>
                )}
            </div>
            <div className="user-details">
                <h2 className="username">{userInfo.username}</h2>
                <p className="email">{userInfo.email}</p>
            </div>
            <div className="membership-section">
                <h3 className="membership-title">会员信息</h3>
                {userInfo.memberships.map((membership, index) => (
                    <div key={index} className="membership-info">
                        <p><strong>会员类型：</strong>{membership.member_type}</p>
                        <p><strong>有效期至：</strong>{new Date(membership.expiry_date).toLocaleDateString()}</p>
                        <p><strong>剩余额度：</strong>{membership.remaining_quota}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Info;
