import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Layout, Input, message } from 'antd';
import ImageGallery from '@components/ImageGallery';
import CategorySelector from '@components/bridge/CategorySelector';
import GenerationControls from '@components/bridge/GenerationControls';
import Login from '@components/login/Login';
import '@styles/QuickPaint.css';
import { loras, bridgeLoras } from '@configs/loras';
import categories from '@configs/categories';
import { sendGenerationRequest } from '@services/apiService';
import Split from 'react-split';
import '@styles/SplitPane.css';
import { withRouter } from '@utils/withRouter';
import { API_ENDPOINTS } from '@configs/api';
import { AssistantContext } from '@contexts/AssistantContext';
import { handleError } from '@utils/apiUtils';
import ImageDisplay from '@components/ImageDisplay';
import { AuthContext } from '@contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import CustomTextArea from '@components/input/CustomTextArea';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';
import Parameters from '@components/bridge/Parameters';

const { Content } = Layout;
const { TextArea } = Input;

function App(props) {
  const [inputOne, setInputOne] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [toggles, setToggles] = useState(loras.map(() => false));
  const [modelVersion, setModelVersion] = useState(bridgeLoras[0].key);
  const [loraInput, setLoraInput] = useState(bridgeLoras[0].value);
  const [resolution, setResolution] = useState('1280x720');
  const [imageDetails, setImageDetails] = useState(null);
  const [imageBatch, setImageBatch] = useState(null);
  const [customWidth, setCustomWidth] = useState(1280);
  const [customHeight, setCustomHeight] = useState(720);
  const [imageCount, setImageCount] = useState(1);
  const [seed, setSeed] = useState(-1);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [hdOption, setHdOption] = useState(false);
  const [buttonPrompt, setButtonPrompt] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const imageGalleryRef = useRef(null);
  const { breadcrumbPath } = useBreadcrumb();

  const { token, isAuthenticated, currentFunc, setCurrentFunc } = useContext(AuthContext);
  const { showBubble } = useContext(AssistantContext);
  const navigate = useNavigate();

  const togglesInfo = useRef(loras.reduce((acc, lora, index) => {
    acc[index] = lora.value;
    return acc;
  }, {})).current;

  const models = useRef(bridgeLoras.reduce((acc, lora) => {
    acc[lora.key] = lora.value;
    return acc;
  }, {})).current;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const { pathname } = props.router.location;
    sessionStorage.setItem('currentRoute', pathname);
    setCurrentFunc("bridge-f");
    updateLoraInput();
  }, []);

  const handleInputOneChange = (e) => {
    setInputOne(e.target.value);
  };

  const handleHdOptionChange = (value) => {
    setHdOption(value);
  };

  const handleResolutionChange = (value) => {
    if (value === 'custom') {
      setResolution('custom');
    } else {
      const [width, height] = value.split('x').map(Number);
      setCustomWidth(width);
      setCustomHeight(height);
      setResolution(value);
    }
  };

  const handleCustomResolutionInput = (key, value) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    if (key === 'customWidth') {
      setCustomWidth(filteredValue);
    } else if (key === 'customHeight') {
      setCustomHeight(filteredValue);
    }
  };

  const handleImageCountChange = (count) => {
    setImageCount(count);
  };

  const updateLoraInput = (updatedState = {}) => {
    const updatedInputOne = updatedState.inputOne !== undefined ? updatedState.inputOne : inputOne;
    const updatedModelVersion = updatedState.modelVersion !== undefined ? updatedState.modelVersion : modelVersion;
    const updatedToggles = updatedState.toggles !== undefined ? updatedState.toggles : toggles;

    const baseInput = updatedInputOne.split('<lora:')[0];
    const modelLoraInput = models[updatedModelVersion];
    const togglesLoraInput = updatedToggles.reduce(
      (input, active, index) => (active ? input + togglesInfo[index] : input),
      ''
    );
    const newLoraInput = baseInput + modelLoraInput + togglesLoraInput;
    setLoraInput(newLoraInput);
  };

  
  const selectImage = (imageDetails, imageBatch) => {
    setSelectedImage(imageDetails.fullUrl);
    setImageDetails(imageDetails);
    setImageBatch(imageBatch);
  };

  const onClickSend = async () => {
    if (!isAuthenticated) {
      setIsLoginModalVisible(true);
      return;
    }

    const { width, height } = resolution === 'custom' ? { width: customWidth, height: customHeight } : parseResolution(resolution);

    const payload = JSON.stringify({
      button_prompt: buttonPrompt,
      prompt: inputOne,
      currentFunc,
      lora: loraInput,
      n_iter: imageCount,
      seed,
      requestClass: "c1",
      cfg_scale: 7,
      width,
      height,
      force_task_id: "f-" + Math.random().toString(36).slice(-8),
    });

    try {
      let response;
      response = await sendGenerationRequest(API_ENDPOINTS.fluxt2i, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error, showBubble);
    }
  };

  const parseResolution = (resolution) => {
    const [width, height] = resolution.split('x').map(Number);
    return { width, height };
  };

  const handleResponse = (response) => {
    if (response.code === 1000) {
      showBubble('提交任务成功！正在生成，请耐心等待哦!');
    }
    imageGalleryRef.current.loadHistory(1, 10, currentFunc);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalVisible(false);
  };

  const handleLoginSuccess = () => {
    imageGalleryRef.current?.loadHistory(1, 10, currentFunc);
    setIsLoginModalVisible(false);
    navigate(0);
  };

  return (
      <Layout style={{ height: '100%', width: '100%', display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
        <div className="flex-container">
          <Content className="l2-left-container">
          <div className="breadcrumb__path">
            {breadcrumbPath.join(' / ')}
          </div>
            <ImageDisplay imageDetails={imageDetails} imageBatch={imageBatch}/>
            <CustomTextArea
              imageCount={imageCount}
              resolution={resolution}
              customWidth={customWidth}
              customHeight={customHeight}
              handleResolutionChange={handleResolutionChange}
              handleCustomResolutionInput={handleCustomResolutionInput}
              handleImageCountChange={handleImageCountChange}
              inputValue={inputOne}
              onInputChange={handleInputOneChange}
              onGenerate={onClickSend}
            />
          </Content>

          <Content className="l2-right-container">
             <Parameters 
                imageCount={imageCount}
                resolution={resolution}
                customWidth={customWidth}
                customHeight={customHeight}
                hdOption={hdOption}
                setHdOption={handleHdOptionChange}
                handleResolutionChange={handleResolutionChange}
                handleCustomResolutionInput={handleCustomResolutionInput}
                handleImageCountChange={handleImageCountChange}
              />
            <div className="breadcrumb__down__section">
              <span className="breadcrumb__path">历史记录</span>
              <ImageGallery
                ref={imageGalleryRef}
                size={10}
                page={1}
                onSelectImage={selectImage}
                selectedImage={selectedImage}
              />
            </div>
          </Content>
          {isLoginModalVisible && (
            <Login onClose={handleLoginModalClose} onLogin={handleLoginSuccess} />
          )}
          </div>
      </Layout>

  );
}

export default withRouter(App);
