import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SelfNavbar from '@components/SelfNavbar';
import Info from '@pages/self/Info';
import MyOrganization from '@pages/self/MyOrganization';
import Login from '@components/login/Login';
import '@styles/Main.css';
import { message } from 'antd';
import 'antd/dist/reset.css';
import { getCurrentUser } from '@services/apiService';
import { AssistantProvider } from '@contexts/AssistantContext';
import { AuthContext } from '@contexts/AuthContext';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';

const Self = () => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isUserPanelVisible, setIsUserPanelVisible] = useState(false);
    const hideTimeoutRef = useRef(null);
    const { currentComponent, switchComponent } = useBreadcrumb();
    const contentRef = useRef(null);
    
    const { isAuthenticated, handleLogout } = useContext(AuthContext);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            getCurrentUser(token).then(response => {
                const { avatar_url, ...userInfo } = response.data;
                setAvatarUrl(avatar_url);
                setUserInfo(userInfo);
            }).catch(error => console.error('Failed to fetch user info:', error));
        }
    }, [isAuthenticated]);

    const handleMouseEnter = () => {
        hideTimeoutRef.current && clearTimeout(hideTimeoutRef.current);
        setIsUserPanelVisible(true);
    };

    const handleMouseLeave = () => {
        hideTimeoutRef.current = setTimeout(() => setIsUserPanelVisible(false), 200);
    };

    const handleLogin = () => {
        setIsLoginModalVisible(false);
        navigate(0)
    };

    const handleToSelf = () => {
        navigate('/Self');
    }

    const renderComponent = () => {
        switch (currentComponent) {
            case 'Info': 
                return <Info />;
            case 'MyOrganization': 
                return <MyOrganization />;
            default: 
                return <Info />;
        }
    };

    return (
        <AssistantProvider>
            <div className="main-layout">
                <div className="header">
                    <h1 onClick={() => navigate('/')} className="clickable-header"> 
                        <img 
                            src={`./logo192.png`}
                            alt="My Icon" 
                            style={{ width: '40px', height: '40px', marginRight: '10px' }} 
                        />
                        CITY AI
                    </h1>
                    {!isAuthenticated ? (
                        <div className="button" onClick={() => setIsLoginModalVisible(true)}>
                            登录
                        </div>
                    ) : avatarUrl && (
                        <div 
                            className="avatar-container"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img src={avatarUrl} alt="用户头像" className="user-avatar" />
                            {isUserPanelVisible && userInfo && (
                                <div className="user-panel">
                                    <div className="user-info-row">
                                        <span className="user-info-label">用户名:</span>
                                        <span className="user-info-value">{userInfo.username}</span>
                                    </div>
                                    <div className="user-info-row">
                                        <span className="user-info-label">邮箱:</span>
                                        <span className="user-info-value">{userInfo.email}</span>
                                    </div>
                                    {userInfo.memberships.map((membership, index) => (
                                        <div key={index}>
                                            <div key={index} className="user-info-row">
                                                <span className="user-info-label">用户类型:</span>
                                                <span className="user-info-value">{membership.member_type}</span>
                                            </div>
                                            <div key={index} className="user-info-row">
                                                <span className="user-info-label">剩余额度:</span>
                                                <span className="user-info-value">{membership.remaining_quota}</span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="button" onClick={handleToSelf}>
                                        用户中心
                                    </div>
                                    <div className="button" onClick={handleLogout}>
                                        登出
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={`main-container ${window.innerWidth <= 768 ? 'vertical' : ''}`}>
                    <SelfNavbar onNavClick={switchComponent} />
                    <div ref={contentRef} className="content">
                            {renderComponent()}
                        </div>
                </div>
                {isLoginModalVisible && (
                    <Login onClose={() => setIsLoginModalVisible(false)} onLogin={handleLogin}/>
                )}
            </div>
        </AssistantProvider>
    );
};

export default Self;
