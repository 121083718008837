import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '@components/Navbar';
import BridgeQuickPaint from '@pages/bridge/BridgeQuickPaint';
import History from '@pages/History';
import Home from '@pages/Home';
import FluxPage from '@pages/FluxPage';
import FluxB from '@pages/bridge/Flux-Bridge';
import Inpaint from '@pages/bridge/Inpaint';
import Transtyle from '@pages/bridge/Transtyle';
import RealPaint from '@pages/bridge/Realpaint';
import Login from '@components/login/Login';
import FeedbackPage from '@pages/feedback/Feedback';
import '@styles/Main.css';
import 'antd/dist/reset.css';
import { getCurrentUser } from '@services/apiService';
import { AssistantProvider } from '@contexts/AssistantContext';
import { AuthContext } from '@contexts/AuthContext';
import { CSSTransition } from 'react-transition-group';
import "@styles/effect/fade.css"
import "@styles/layout/main-layout.css"
import { useBreadcrumb } from '@contexts/BreadcrumbContext';

const Main = () => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isUserPanelVisible, setIsUserPanelVisible] = useState(false);
    const hideTimeoutRef = useRef(null);
    
    const { isAuthenticated, handleLogout } = useContext(AuthContext);
    const navigate = useNavigate();
    const contentRef = useRef(null);
    const { currentComponent, switchComponent } = useBreadcrumb();
    
    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            getCurrentUser(token).then(response => {
                const { avatar_url, ...userInfo } = response.data;
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                setAvatarUrl(avatar_url);
                setUserInfo(userInfo);
            }).catch(error => console.error('Failed to fetch user info:', error));
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (currentComponent === 'Self') {
            navigate('/Self');
        }
    }, [currentComponent, navigate]);

    
    const handleMouseEnter = () => {
        hideTimeoutRef.current && clearTimeout(hideTimeoutRef.current);
        setIsUserPanelVisible(true);
    };

    const handleMouseLeave = () => {
        hideTimeoutRef.current = setTimeout(() => setIsUserPanelVisible(false), 200);
    };

    const handleLogin = () => {
        setIsLoginModalVisible(false);
        navigate(0)
    };

    const handleToSelf = () => {
        navigate('/Self');
    }

    const renderComponent = () => {
        switch (currentComponent) {
            case 'Home': 
                return <Home />;
            case 'BridgeQuickPaint': 
                return <BridgeQuickPaint />;
            case 'Inpaint': 
                return <Inpaint />;
            case 'FluxView': 
                return <FluxPage key="view-f" inputCurrentFunc="view-f" />;
            case 'FluxAttach': 
                return <FluxPage key="attach-f" inputCurrentFunc="attach-f" />;
            case 'Transtyle': 
                return <Transtyle />;
            case 'RealPaint': 
                return <RealPaint />;
            case 'Flux-Bridge': 
                return <FluxB />;
            case 'Road': 
                return <FluxPage key="road-f" inputCurrentFunc="road-f" />;
            case 'Tunnel': 
                return <FluxPage key="tunnel-f" inputCurrentFunc="tunnel-f" />;
            case 'History': 
                return <History />;
            case 'Feedback': 
                return <FeedbackPage />;
            default: 
                return null;
        }
    };

    return (
        <AssistantProvider>
            <div className="main-layout">
                <div className="header">
                <h1 
                    onClick={() => navigate('/')} 
                    style={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        textAlign: 'center' 
                    }}
                    className="clickable-header"
                >
                    <img 
                        src={`./logo192.png`}
                        alt="My Icon" 
                        style={{ width: '40px', height: '40px', marginRight: '10px' }} 
                    />
                    City AI
                </h1>

                    {/* <div className="scroll-title">
                        删档测试，请及时保存图片资产！欢迎各位提交反馈，测试中，如额度用完可发送反馈给您充值！
                    </div> */}
                    {!isAuthenticated ? (
                        <div className="button" onClick={() => setIsLoginModalVisible(true)}>
                            登录
                        </div>
                    ) : avatarUrl && (
                        <div 
                            className="avatar-container"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img src={avatarUrl} alt="用户头像" className="user-avatar" />
                            {isUserPanelVisible && userInfo && (
                                <div className="user-panel">
                                    <div className="user-info-row">
                                        <span className="user-info-label">用户名:</span>
                                        <span className="user-info-value">{userInfo.username}</span>
                                    </div>
                                    <div className="user-info-row">
                                        <span className="user-info-label">邮箱:</span>
                                        <span className="user-info-value">{userInfo.email}</span>
                                    </div>
                                    {userInfo.memberships.map((membership, index) => (
                                        <div key={index}>
                                            <div key={index} className="user-info-row">
                                                <span className="user-info-label">用户类型:</span>
                                                <span className="user-info-value">{membership.member_type}</span>
                                            </div>
                                            <div key={index} className="user-info-row">
                                                <span className="user-info-label">剩余额度:</span>
                                                <span className="user-info-value">{membership.remaining_quota}</span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="button" onClick={handleToSelf}>
                                        用户中心
                                    </div>
                                    <div className="button" onClick={handleLogout}>
                                        登出
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="main-container ${window.innerWidth <= 768 ? 'vertical' : ''}">
                    <Navbar onNavClick={switchComponent} />
                    <div ref={contentRef} className="content">
                            {renderComponent()}
                    </div>
                </div>
                {isLoginModalVisible && (
                    <Login onClose={() => setIsLoginModalVisible(false)} onLogin={handleLogin}/>
                )}
            </div>
        </AssistantProvider>
    );
};

export default Main;
