import React, { useState } from 'react';
import '@styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreadcrumb } from '@contexts/BreadcrumbContext';

import {
  faRoad,
  faTree,
  faHouse,
  faBuilding,
  faBook,
  faHistory,
  faComment,
  faUser,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ onNavClick }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const { setBreadcrumbPath } = useBreadcrumb();

  const mainMenuItems = [
    { name: 'Home', label: '首页', icon: faHouse },
    {
      name: 'RBT',
      label: '道桥隧',
      icon: faRoad,
      subMenu: [
        {
          name: 'Road',
          label: '道路',
          thirdLevelMenu: [{ name: 'Road', label: '快速出图' }],
        },
        {
          name: 'Bridge',
          label: '桥梁',
          thirdLevelMenu: [
            { name: 'BridgeQuickPaint', label: '快速出图' },
            { name: 'RealPaint', label: '实景生图' },
            { name: 'Inpaint', label: '局部修改' },
            { name: 'Transtyle', label: '风格修改' },
            { name: 'Flux-Bridge', label: '超级模型' },
          ],
        },
        {
          name: 'Tunnel',
          label: '隧道',
          thirdLevelMenu: [{ name: 'Tunnel', label: '快速出图' }],
        },
      ],
    },
    {
      name: 'View',
      label: '景观',
      icon: faTree,
      subMenu: [{ name: 'FluxView', label: '超级模型' }],
    },
    {
      name: 'CA',
      label: '城市附属',
      icon: faBuilding,
      subMenu: [{ name: 'FluxAttach', label: '超级模型' }],
    },
  ];

  const bottomMenuItems = [
    { name: 'Self', label: '个人', icon: faUser },
    // { name: 'Guide', label: '指南', icon: faBook },
    { name: 'History', label: '历史', icon: faHistory },
    { name: 'Feedback', label: '反馈', icon: faComment },
  ];

  const handleMenuClick = (item) => {
    if (item.subMenu && item.subMenu.length > 0) {
      if (activeMenu === item.name) {
        return; // 不改变 activeMenu 的状态
      }
      setActiveMenu(item.name);
      setActiveSubMenu(null);
    } else {
      setBreadcrumbPath([item.label]);
      onNavClick(item.name);
      setActiveMenu(null);
    }
  };
  
  const handleSubMenuClick = (subItem) => {
    if (subItem.thirdLevelMenu) {
      // 如果点击的是当前活动的子菜单项，则保持展开状态
      if (activeSubMenu === subItem.name) {
        return; // 不改变 activeSubMenu 的状态
      }
      setActiveSubMenu(subItem.name);
    } else {
      setBreadcrumbPath([subItem.label]);
      onNavClick(subItem.name);
      setActiveMenu(null);
      setActiveSubMenu(null);
    }
  };
  

  const handleThirdMenuClick = (thirdItem) => {
    setBreadcrumbPath([
      mainMenuItems.find((menu) => menu.name === activeMenu)?.label,
      mainMenuItems
        .find((menu) => menu.name === activeMenu)
        ?.subMenu.find((sub) => sub.name === activeSubMenu)?.label,
      thirdItem.label,
    ]);

    onNavClick(thirdItem.name);
    setActiveMenu(null);
    setActiveSubMenu(null);
  };

  return (
    <div className="navbar">
      <ul className="main-menu">
        {mainMenuItems.map((item) => (
          <li key={item.name} className="menu-item-wrapper">
            <div onClick={() => handleMenuClick(item)} className="menu-item">
              <div className="menu-item-content">
                <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                <span>{item.label}</span>
                {item.subMenu && (
                  <FontAwesomeIcon
                    icon={
                      activeMenu === item.name ? faChevronUp : faChevronDown
                    }
                    className="menu-arrow"
                  />
                )}
              </div>
            </div>
            {activeMenu === item.name && item.subMenu && (
              <ul className="submenu">
                {item.subMenu.map((subItem) => (
                  <li key={subItem.name} className="submenu-item" onClick={() => handleSubMenuClick(subItem)}>
                    <div
                      className="submenu-label"
                    >
                      <span>{subItem.label}</span>
                      {subItem.thirdLevelMenu && (
                        <FontAwesomeIcon
                          icon={
                            activeSubMenu === subItem.name
                              ? faChevronUp
                              : faChevronDown
                          }
                          className="submenu-arrow"
                        />
                      )}
                    </div>
                    {activeSubMenu === subItem.name &&
                      subItem.thirdLevelMenu && (
                        <ul className="third-menu">
                          {subItem.thirdLevelMenu.map((thirdItem) => (
                            <li
                              key={thirdItem.name}
                              onClick={() => handleThirdMenuClick(thirdItem)}
                              className="third-menu-item"
                            >
                              {thirdItem.label}
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <ul className="bottom-menu">
        {bottomMenuItems.map((item) => (
          <li key={item.name} className="bottom-item">
            <a
              onClick={() => onNavClick(item.name)}
              className="menu-item"
            >
              <div className="menu-item-content">
                <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                <span>{item.label}</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
  