import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer__container">
            <div className="footer__column footer__logo">
                <img 
                    src="./logo512.png"
                    alt="My Icon" 
                    className="footer__logo-img"
                />
            </div>
            <div className="footer__column footer__about">
                <h3 className="footer__column-title">关于平台</h3>
                <ul className="footer__about-list">
                    <li className="footer__about-item"><a className="footer__about-link">用户协议</a></li>
                    <li className="footer__about-item"><a className="footer__about-link">隐私政策</a></li>
                </ul>
            </div>
            <div className="footer__column footer__tech-stack">
                <h3 className="footer__column-title">技术栈</h3>
                <p className="footer__tech-stack-text">React</p>
            </div>
            <div className="footer__record-info">
                <p className="footer__record-text">
                    <a href="https://www.12377.cn" className="footer__record-link">网上有害信息举报专区</a>
                    <a href="https://www.12377.cn" className="footer__record-link">中央网信办（国家网信办）违法和不良信息举报中心</a>
                    <a href="http://www.shdf.gov.cn/shdf/channels/740.html" className="footer__record-link">扫黄打非举报专区</a>
                </p>
                <p className="footer__record-text">© 2024 上海智玖城智能科技有限公司 | ICP备案号：审批中</p>
            </div>
        </footer>
    );
};

export default Footer;
